<template>
    <div v-if="initializing || submitting">
        <Loading />
    </div>
    <div class="login-form max500" v-else>
        <h1 class="top-0 bottom-20" v-if="isNewUser">Set Up Your Account Password</h1>
        <h1 class="top-0" v-else>Reset Password</h1>
        <ValidationObserver>
            <form @submit.prevent="submit">
                <Input
                    type="password"
                    true_type='password'
                    v-model="password"
                    label="New Password"
                    placeholder="New Password"
                    required
                    name="password"
                    id="password"
                    class="fullwidth relative block"
                    new-password
                />
                <button type="submit">Submit</button>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver } from 'vee-validate';
    import { auth } from '@/util/apiRequests';
    import { mapState } from 'vuex';
    import Loading from '@/components/general/loading/loading';

    export default {
        name: 'ResetPassword',
        components: { Loading, ValidationObserver },
        props: {
            t: String
        },
        data() {
            return {
                password: '',
                initializing: false,
                submitting: false,

            };
        },
        computed: {
            token() {
                return this.$route.query.t;
            },
            isNewUser() {
                return this.$route.query.newUser?.toLowerCase() === 't';
            },
            ...mapState({
                user: 'user'
            })
        },
        methods: {
            async submit() {
                if (this.token) {
                    let host;

                    if (!this.user) {
                        host = window.location.host;
                    }

// const regex = new RegExp(
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/
// )
//
//                   this.$cl(regex)
//
//                   const isValidPassword = this.password.match(regex);
//
//                   if (!isValidPassword) {
//                     this.$toasted.error('Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character.');
//                     return;
//                   }

                    this.submitting = true;
                    const pwRes = await this.$api.patch(auth.updatePassword(), {
                        password: this.password,
                        token: this.token,
                        host: window.location.host
                    });

                    const signInRes = await this.$api.post(auth.signIn(), {
                        email: pwRes.data.email,
                        password: this.password,
                        host: window.location.host
                    });

                    this.submitting = false;
                  if (pwRes.status < 200 || pwRes.status >= 300 || signInRes.status < 200 || signInRes.status >= 300) {

                    if(pwRes.data[0] === "password"){
                      this.$toasted.error('You need lowercase letters, an uppercase letter, a number and special character!');
                    } else {
                      this.$toasted.error('There was an error resetting your password. Please try again.');
                    }
                  } else {
                    this.$store.commit('persistUser', signInRes.data.user);
                    this.$store.commit('persistBuildId', signInRes.data.buildId)
                    this.$toasted.success('New password has been accepted');
                    await this.$router.push({name: 'Dashboard'});
                  }
                }
            }
        },
        async mounted() {
            this.initializing = true;

            if (!this.token) {
                this.$toasted.error('You must follow the link sent to your email to access that page');
                await this.$router.replace({ name: 'SignIn' });
            }

            const res = await this.$api.get(auth.checkToken(this.token));

            if (res.status === 400) {
                this.$toasted.error(
                    'That link has already been used to set your password. Please contact your system administrator if you cannot access your account and need to reset your password',
                    { duration: 6000 }
                );
                await this.$router.replace({ name: 'SignIn' });
            }

            this.initializing = false;
        }
    };
</script>